import React from 'react'
import {Row, Col, Container} from 'react-bootstrap'
import BaffleText from 'components/baffle-text'
import AnimationContainer from 'components/animation-container'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faReact, faAngular, faAws} from '@fortawesome/free-brands-svg-icons'
import {
    faPencilRuler,
    faServer,
    faRobot,
    faSmileBeam,
    faCode,
    faCoffee,
    faCodeBranch,
    faAppleAlt,
    faCloud,
    faLaptopCode,
    faDatabase,
    faCartPlus
} from '@fortawesome/free-solid-svg-icons'
import Counter from 'components/counter'
import ThemeContext from '../../context'
import './styles.scss'

class Services extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
        }
        this.show = this.show.bind(this)
    }

    static contextType = ThemeContext

    show() {
        this.setState({show: true})
    }

    render() {
        return (
            <section
                id={`${this.props.id}`}
                className="services"
                style={{height: this.context.height}}
            >
                <Row
                    className="top"
                    style={{
                        maxHeight:
                            this.context.height !== 'auto'
                                ? this.context.height * 0.8
                                : 'inherit',
                    }}
                >
                    <div className="content">
                        <Col md={12}>
                            <div className="line-text">
                                <h4>Služby</h4>
                            </div>
                            <div className="heading">
                                <BaffleText
                                    text="Co pro vás mohu udělat?"
                                    revealDuration={500}
                                    revealDelay={500}
                                    parentMethod={this.show}
                                    callMethodTime={1100}
                                />
                            </div>
                            <div
                                className="services_container"
                                style={{
                                    minHeight:
                                        this.context.height !== 'auto'
                                            ? this.context.height * 0.6
                                            : 'inherit',
                                }}
                            >
                                <Container>{this.services()}</Container>
                            </div>
                        </Col>
                    </div>
                </Row>
                <Row className="bottom">{this.counters()}</Row>
            </section>
        )
    }

    services() {
        if (this.state.show || this.context.height === 'auto') {
            return (
                <Row>
                    <Col md={6} className="service">
                        <AnimationContainer delay={200} animation="fadeInLeft fast">
                            <div className="icon">
                                <FontAwesomeIcon icon={faCodeBranch}/>
                            </div>
                            <h4>Backend vývoj</h4>
                            <p>
                                Vývoj kompletního systému. Od API až po složité administrační prostředí. Vše s důrazem
                                na rychlost a kvalitu. Napojení na různé databazové systémy, služby třetích stran.
                                Backend je srdcem každé aplikace, ať už mobilní či webové.
                            </p>
                        </AnimationContainer>
                    </Col>
                    <Col md={6} className="service border-side">
                        <AnimationContainer delay={400} animation="fadeInDown fast">
                            <div className="icon">
                                <FontAwesomeIcon icon={faReact}/>
                            </div>
                            <h4>React vývoj</h4>
                            <p>
                                Vývoj front-endu na nástroji 21. století. Rychlost načítání, plynulost přechodů mezi
                                stránkama, bezpečnost, to je jen pár z mnoha výhod Reactového vývoje. Pokud vám záleží
                                na uživatelích, Kontaktujte mě.
                            </p>
                        </AnimationContainer>
                    </Col>
                    <Col md={6} className="service">
                        <AnimationContainer delay={600} animation="fadeInRight fast">
                            <div className="icon">
                                <FontAwesomeIcon icon={faAppleAlt}/>
                            </div>
                            <h4>iOS vývoj</h4>
                            <p>
                                Vlastní mobilní aplikace, třešnička na dortu každé značky. Přístup k nativním funkcím
                                systému, posílání notifikací, eshop, (doplňte svůj nápad). Kontaktujte mě a určitě něco
                                vymyslíme.
                            </p>
                        </AnimationContainer>
                    </Col>
                    <Col md={6} className="service border-side">
                        <AnimationContainer delay={1000} animation="fadeInUp fast">
                            <div className="icon">
                                <FontAwesomeIcon icon={faCartPlus} className="solid"/>
                            </div>
                            <h4>Ecommerce</h4>
                            <p>
                                Ecommerce se každým rokem stává důležitější části internetu. Mohu pro vás vytvořit
                                eshop, od vývoje "na zelené louce", přes Prestashop a Woocommerce až po Shopify. Rychlé
                                načítání, SEO, speciální požadavky, nic není nemožné.
                            </p>
                        </AnimationContainer>
                    </Col>
                </Row>
            )
        }
    }

    counters() {
        if (this.state.show || this.context.height === 'auto') {
            return (
                <Container>
                    <Col md={4}>
                        <AnimationContainer delay={100} animation="fadeIn fast">
                            <Counter
                                icon={faSmileBeam}
                                value={40}
                                text="Štastných klientů"
                                symbol="+"
                                duration={3}
                            />
                        </AnimationContainer>
                    </Col>
                    <Col md={4}>
                        <AnimationContainer delay={100} animation="fadeIn fast">
                            <Counter
                                icon={faCoffee}
                                value={1000}
                                text="Šálků kávy"
                                symbol="+"
                                duration={3}
                            />
                        </AnimationContainer>
                    </Col>
                    <Col md={4}>
                        <AnimationContainer delay={100} animation="fadeIn fast">
                            <Counter
                                icon={faCode}
                                value={50000}
                                text="Řádků kódu"
                                symbol="+"
                                duration={3}
                            />
                        </AnimationContainer>
                    </Col>
                </Container>
            )
        }
    }
}

export default Services
