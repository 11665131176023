import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import {Row, Col} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>O mně</h4>
                            </div>
                            <h3>Developer a data-freak</h3>
                            <div className="separator"/>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus suscipit nisi vitae
                                feugiat vestibulum. Aliquam porta nulla vel odio scelerisque, pretium volutpat dui
                                euismod. Integer porttitor dolor placerat malesuada dictum. Fusce enim dolor, dignissim
                                quis ornare at, elementum nec turpis. Donec ac interdum libero, sed condimentum lectus.
                                Nunc nec iaculis tortor. Donec interdum sollicitudin eros in pharetra. Donec ultricies
                                laoreet dictum. Maecenas vestibulum sodales justo, id hendrerit orci aliquet gravida.
                                Nulla facilisi.</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon"
                                                 onClick={() => window.open('https://www.linkedin.com/in/jakubweb/')}/>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                        <div className="line-text">
                            <h4>Zkušenosti</h4>
                        </div>
                        <div className="skills-container">
                            <Progress name="PHP" value={90} delay={1100}/>
                            <Progress name="Laravel" value={80} delay={1100}/>
                            <Progress name="React" value={70} delay={1100}/>
                            <Progress name="Swift" value={60} delay={1100}/>
                            <Progress name="Docker" value={70} delay={1100}/>
                            <Progress name="CI/CD" value={70} delay={1100}/>
                        </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
                }}/>
        )
    }

}

export default Hero
