import React from 'react'
import './styles.scss'
import { Row, Col } from 'react-bootstrap'
import AnimationContainer from 'components/animation-container'
import BaffleText from 'components/baffle-text'
import ThemeContext from '../../context'

class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            message: "",
            error: false,
            show: false
        }
        this.show = this.show.bind(this)
    }
    static contextType = ThemeContext


    show() {
      this.setState({show : true})
    }

    check(val) {
        return !(this.state.error && val === "");
    }

    encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    render() {
        return (
            <section id={`${this.props.id}`} className="contact" style={{height: this.context.height}}>
                <Row>
                    <Col md={2} className="side">
                        <h2>
                            <BaffleText text="Kontakt" revealDuration={500} revealDelay={500} parentMethod={this.show} callMethodTime={1100} />
                        </h2>
                    </Col>
                    <Col md={10} className="form">
                        {this.form()}
                    </Col>
                </Row>
            </section>
        )
    }

    form() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer delay={0} animation="fadeInUp fast">
                <div className="form-container">
                    <form method="POST" action="https://getform.io/f/0e252825-31c0-40ab-9f99-2918a5027680" name="contact">

                        <div className="line-text">
                            <h4>Kontaktujte mě</h4>

                            <AnimationContainer delay={50} animation="fadeInUp fast">
                                <div className="form-group">
                                    <input type="text" name={"name"} className={`name ${this.check(this.state.name) ? "" : "error"}`} placeholder="Jméno" onChange={e => this.setState({name: e.target.value})} />
                                </div>
                            </AnimationContainer>

                            <AnimationContainer delay={100} animation="fadeInUp fast">
                            <div className="form-group">
                                <input type="text" name={"email"} className={`email ${this.check(this.state.email) ? "" : "error"}`} placeholder="Email" onChange={e => this.setState({email: e.target.value})} />
                            </div>
                            </AnimationContainer>

                            <AnimationContainer delay={200} animation="fadeInUp fast">
                            <div className="form-group">
                                <textarea name={"message"} className={`message ${this.check(this.state.message) ? "" : "error"}`} placeholder="Zpráva" onChange={e => this.setState({message: e.target.value})}/>
                            </div>
                            </AnimationContainer>

                            <AnimationContainer delay={250} animation="fadeInUp fast">
                            <div className="submit">
                                <button className={`hover-button ${this.state.error ? "error" : ""}`} type={"submit"}>
                                    <span>Odeslat zprávu</span>
                                </button>
                            </div>
                            </AnimationContainer>
                        </div>
                    </form>
                </div>
                </AnimationContainer>
            )
        }
    }

    map() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer delay={1000} animation="fadeIn fast" height={this.context.height}>
                    <iframe title="map" width="100%" height="100%" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street%2C%20Dublin%2C%20Ireland+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"/>
                </AnimationContainer>
            )
        }
    }

}

export default Contact
